import { Controller } from 'stimulus';
import { v4 as uuidv4 } from 'uuid';
import '../js/button';


let lang = $('html').attr('lang');

if (lang != 'en' && lang != 'es') {
    lang = 'fr';
}

const translation = {
    fr: {
        close: 'Fermer',
        delete: 'Supprimer',
        add: 'Ajouter',
        edit: 'Modifier',
        valid: 'Valider',
        confirm: 'Confirmer'
    },
    en: {
        close: 'Close',
        delete: 'Delete',
        add: 'Add',
        edit: 'Edit',
        valid: 'Validate',
        confirm: 'Confirm'
    },
    es: {
        close: 'Cerrar',
        delete: 'Borrar',
        add: 'Agregar',
        edit: 'Editar',
        valid: 'Validar',
        confirm: 'Confirmar'
    }
}[lang]

/**
 *
 */
export default class extends Controller {
    static values = {
        url: String, // Si il y a un contenu à charger en ajax
        target: String, // ID d'une modal existante dans le DOM, si l'Id n'existe pas, la modal est créée avec cet ID, si le champ n'est pas renseigné, l'Id est alléatoire et la modal est créée
        title: String, // Si il faut changer le titre de la modal
        message: String,
        type: String,
        options: Object,
        data: Object,
        previous: String, // garde en mémoire l'ancienne modal pour la re-ouvrir par la suite
        button: Object
    }

    static targets = ["query"]

    initialize() {
        if (!this.hasTargetValue) {
            this.targetValue = '#modal_' + uuidv4().replaceAll('-', '_');
        }

        if (!['delete', 'add', 'edit', 'valid'].includes(this.typeValue)) {
            this.typeValue = 'valid';
        }

        let options = $.extend({
            centered: true,
            scrollable: true,
            fullscreen: false,
            size: null,
            focus: false
        }, this.optionsValue);

        this.$modal = $(this.targetValue);

        if (this.$modal.length === 0) {
            this.createModal(options);
        }

        this.modal = bootstrap.Modal.getOrCreateInstance(this.$modal[0], options);

        if(this.hasPreviousValue){
            this.modalPrevious = bootstrap.Modal.getInstance(document.querySelector(this.previousValue));

            this.$modal.on('click', event => {
                if($(event.target).data('bsDismiss') === 'modal'){
                    this.modalPrevious.show()
                }
            });
        }

        this.$modal.on('modal.reloadForm', e => {
            this.loadForm(e)
        })
    }

    disconnect() {
        if (this.$modalBtn !== undefined) {
            this.$modalBtn.off('click');
        }
    }

    onFormLoad(event) {
        this.modal._config['backdrop'] = 'static';

        this.$modal.trigger('modal.formLoad', event);

        this.$modal.find('[data-bs-toggle="tooltip"]').each(function() {
            return new bootstrap.Tooltip(this)
        });
        this.$modal.find('form').on('submit', () => {
            if (this.$modal.find('form')[0].reportValidity()) {
                const $formSubmit = this.$modal.find('[data-submit="modal"],:submit');

                $formSubmit.btnLoader();

                this.$modal.trigger('modal.beforeSubmit', event);

                this.$modal.find('form').ajaxSubmit({
                    data: this.dataValue,
                    success: (response) => {
                        if (typeof response === 'object') {
                            if (response.redirect) {
                                if (response.redirect === 'current') {
                                    window.location.reload();
                                } else {
                                    window.location.replace(response.redirect);
                                }
                            }
                            if (response.notify) {
                                $.notify(response.notify, 'success');
                            }
                            this.modal.hide();
                            if(this.hasPreviousValue){
                                this.modalPrevious.show();
                                if (response.loadPrevious) {
                                    $(this.previousValue).find('.modal-body').html(
                                        '<div class="d-flex justify-content-center">\n' +
                                        '  <div class="spinner-border text-primary" role="status">\n' +
                                        '    <span class="visually-hidden">Loading...</span>\n' +
                                        '  </div>\n' +
                                        '</div>'
                                    );

                                    $(this.previousValue).find('.modal-body').load(response.loadPrevious);
                                }
                            }
                            $formSubmit.btnLoader('reset');
                            this.$modal.trigger('modal.formSuccess', response);
                        }
                        else {
                            this.$modal.find('.modal-body').html(response);
                            this.onFormLoad(event);
                            $formSubmit.btnLoader('reset');
                            this.$modal.trigger('modal.formContinu', response);
                        }
                    },
                    error: (response) => {
                        if (response.responseText !== undefined) {
                            this.$modal.find('.modal-body').html(response.responseText);
                            this.onFormLoad(event);
                        }
                        $formSubmit.btnLoader('reset');
                        this.$modal.trigger('modal.formError');
                    }
                });
            }

            return false;
        });
    }

    /**
     * Prend en compte la validation d'un formulaire
     */
    loadForm(event) {
        if (this.$modalBtn === undefined) {
            this.$modalBtn = this.$modal.find('.modal-footer > :submit');

            if (this.$modalBtn.length === 0) {
                let button = $.extend({
                    class: this.typeValue === 'delete' ? 'btn btn-danger' : 'btn btn-primary',
                    value: translation[this.typeValue]
                }, this.buttonValue);
                this.$modalBtn = $('<button type="submit" class="' + button.class + '">' + button.value + '</button>')
                this.$modal.find('.modal-footer').html('<button type="button" class="btn btn-light" data-bs-dismiss="modal">' + translation['close'] + '</button>');
                this.$modal.find('.modal-footer').append(this.$modalBtn);
            }

            this.$modalBtn.on('click', () => {
                this.$modal.trigger('modal.clickSubmit', event);
                this.$modal.find('form[name]').trigger('submit');
            });
        }

        this.loadHtml(event, () => {
            this.onFormLoad();
        });
    }


    /*reload(){
       $('[name="result"]').ajaxForm({
           complete: function(jqXHR) {
               $('#exampleModal .modal-body').html(jqXHR.responseText);
           }
       });
   };*/

    loadHtml(event, onLoad) {
        this.show(event);
        this.$modal.find('.modal-body').html(
            '<div class="d-flex justify-content-center">\n' +
            '  <div class="spinner-border text-primary" role="status">\n' +
            '    <span class="visually-hidden">Loading...</span>\n' +
            '  </div>\n' +
            '</div>'
        );

        /*if(this.hasDataValue){
            options.data = this.dataValue
        }*/

        this.$modal.data('modalUrl', this.urlValue);

        $.ajax({
            url: this.urlValue,
            data: this.dataValue,
            success: response => {
                this.$modal.find('.modal-body').html(response);
                this.$modal.trigger('modal.htmlLoad', event);
                if (typeof onLoad === 'function') onLoad(event);
            },
            error: response => {
                this.$modal.trigger('modal.htmlLoad.error', response);
            },
        });

        /*$.ajax({
            url: this.urlValue,
            data: this.dataValue,
            success: response => {
                this.$modal.find('.modal-body').html(response);
                if(typeof onLoad === 'function') onLoad(event);
            }
        });*/
    }

    show(event) {
        event.preventDefault();

        // Todo, c'est du vieux code encore utilisé pour les tags questionnaire
        if ($(event.target).attr('data-bs-dismiss') === 'modal') {
            let oldModalEl = document.getElementById($(event.target).closest('.modal').attr('id'));
            let oldModal = bootstrap.Modal.getInstance(oldModalEl);
            this.$modal.on('hide.bs.modal', function(event) {
                if(oldModal)
                    oldModal.show();
                $(oldModalEl).trigger('modal.nextClosed');
            })
        }

        if(this.hasTitleValue){
            this.$modal.find('.modal-title').html(this.titleValue);
        }

        if(this.hasPreviousValue){
            this.modalPrevious.hide();
        }
        this.modal.show();
    }

    confirm(event) {
        this.$modal.find('.modal-body').html('<div class="alert alert-warning">' + this.messageValue + '</div>');

        if (this.$modalBtn === undefined) {
            let button = $.extend({
                class: 'btn btn-danger',
                value: translation['confirm']
            }, this.buttonValue);
            this.$modalBtn = $('<button class="' + button.class + '" data-bs-dismiss="modal">' + button.value + '</button>')
            this.$modal.find('.modal-footer').html('<button type="button" class="btn btn-light" data-bs-dismiss="modal">' + translation['close'] + '</button>');
            this.$modal.find('.modal-footer').append(this.$modalBtn);

            this.$modalBtn.on('click', () => {
                $(event.target).trigger('modal.confirmSuccess');
            });
        }

        this.show(event);
    }

    createModal(options) {
        let id = this.targetValue.replace('#', '');

        this.$modal = $(
            '<div id="' + id + '" class="modal fade" tabindex="-1" aria-labelledby="' + id + '_label" aria-hidden="true">' +
            '   <div class="modal-dialog ' + this.classModalDialog(options) + '">' +
            '       <div class="modal-content">' +
            '           <div class="modal-header">' +
            '               <h5 class="modal-title" id="' + id + '_label"></h5>' +
            '               <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>' +
            '           </div>' +
            '           <div class="modal-body">' +
            '           </div>' +
            '           <div class="modal-footer">' +
            '               <button type="button" class="btn btn-light" data-bs-dismiss="modal">' + translation['close'] + '</button>' +
            '           </div>' +
            '       </div>' +
            '   </div>' +
            '</div>'
        );

        $('body').append(this.$modal);
    }

    classModalDialog(options) {
        let classDialog = '';
        if (options.centered) {
            classDialog += ' modal-dialog-centered';
        }
        if (options.scrollable) {
            classDialog += ' modal-dialog-scrollable';
        }
        if (options.fullscreen) {
            let down = '';
            if (options.fullscreen !== true) {
                down = '-' + options.fullscreen;
            }
            classDialog += ' modal-fullscreen' + down;
        }
        if (options.size !== null) {
            classDialog += ' modal-' + options.size;
        }

        return classDialog;
    }
}