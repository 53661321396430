/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';

// start the Stimulus application
import './bootstrap';

import jQuery from 'jquery';
global.$ = global.jQuery = jQuery;

import * as bootstrap from 'bootstrap5';

import 'jquery-form';

import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';
import 'select2';
import 'select2/dist/css/select2.min.css';
import 'select2-bootstrap-5-theme/dist/select2-bootstrap-5-theme.min.css';
import '../vendor/tetranz/select2entity-bundle/Resources/public/js/select2entity'


import 'bootstrap-table/src/bootstrap-table';
import 'bootstrap-table/src/extensions/cookie/bootstrap-table-cookie';
import 'bootstrap-table/src/locale/bootstrap-table-fr-FR';


$.fn.changeMenu = function(scroll){

    /*if(scroll < 10){
        $(this).removeClass('shadow bg-gradient-primary');
        $(this).addClass('bg-transparent');
    }
    else{
        $(this).removeClass('bg-transparent');
        $(this).addClass('shadow bg-gradient-primary');
    }*/


    let oVal = (scroll / 3);
    $('.page-header').css({
        'transform': 'translate3d(0,' + oVal + 'px,0)',
        '-webkit-transform': 'translate3d(0,' + oVal + 'px,0)',
        '-ms-transform': 'translate3d(0,' + oVal + 'px,0)',
        '-o-transform': 'translate3d(0,' + oVal + 'px,0)'
    });
};

$(document).ready(function() {
    //var myModal = new bootstrap.Modal(document.getElementById('reglement_modal'), options)



    const toastAlerts = document.getElementsByClassName('toast-alert')

    Array.prototype.forEach.call(toastAlerts, function(toastAlert) {
        const toast = new bootstrap.Toast(toastAlert)

        toast.show()
    });





    let $navbar = $('nav.navbar');
    $navbar.changeMenu($(window).scrollTop());
    $(window).scroll(function (event) {
        $navbar.changeMenu($(window).scrollTop());
    });

    $('.ajax-form').submit(function() {
        let $container = $(this).closest('div');

        $(this).ajaxSubmit({
            success: function (data) {
                $container.html(data);
            }
        });
        return false;
    });

    $.fn.loadTable = function(){
        let $table = $(this);
        $table.find('th>a,a.page-link').on('click', function(){
            let $container = $(this).closest('.ajax-table');

            $container.load($(this).attr('href'), function(){
                $table.loadTable();
            });

            return false;
        });
    };

    $('.ajax-table').each(function(){
        $(this).load($(this).data('target'), function(){
            $(this).loadTable();
        });
    });

    $(document).on('click', '.ajax-modal', function(){
        if($.isString($(this).attr('title'))){

        }
        if($.isString($(this).data('title'))){

        }
        $('#mainModal').modal('show');
    });

});
