import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "slide" ]
    static values = { index: Number }

    connect() {
        console.log('connect');
    }

    initialize() {
        console.log('initialize');
    }

    select(event) {
        this.indexValue = event.target.value;
    }

    next() {
        this.indexValue++
    }

    previous() {
        this.indexValue--
    }

    indexValueChanged() {
        this.showCurrentSlide()
    }

    showCurrentSlide() {
        this.slideTargets.forEach((element, index) => {
            element.hidden = index != this.indexValue
        })
    }
}