
// Loader pour les btns
export default $.fn.btnLoader = function (type) {
    $(this).each(function(){
        if(type === 'reset') {
            $(this).html($(this).data('html'));
            $(this).attr('disabled', false);
        }
        else {
            $(this).data('html', $(this).html());
            //let width = $(this).width();
            $(this).html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>');
            //$(this).width(width);
            $(this).attr('disabled', true);
        }
    });
};